
import { Vue, Options } from 'vue-class-component';
import dayjs from 'dayjs';
import debounce from 'lodash-es/debounce';
// Cornerstone
// @ts-ignore
import cornerstone from 'cornerstone-core/dist/cornerstone.min.js';
// @ts-ignore
import cornerstoneTools from 'cornerstone-tools/dist/cornerstoneTools.min.js';
// @ts-ignore
import cornerstoneWebImageLoader from 'cornerstone-web-image-loader';
// @ts-ignore
import cornerstoneMath from 'cornerstone-math';
import Hammer from 'hammerjs';
import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { Viewer, StudyGallery, PageLoading } from '@/lib/components';
import { ISuggestion, Tool } from '@/lib';
import {
  Anatomy,
  Annotation,
  FullIndicator,
  ImageSeries,
  Inadequacy,
  InadequateReason,
  Indicator,
  IndicatorRequest,
  PaginatedResponse,
  Patient,
  Study
} from '@/models';

import {
  AnatomyService,
  ZephyrImageService,
  InadequateReasonService,
  IndicatorService,
  PatientReviewIndicatorService,
  PatientSeriesService,
  ZephyrAnnotationService,
  ZephyrService
} from '@/services/api';
import { imageLoaderService } from '@/services';
import { LoaderTask } from '@/services/image-loader.service';
import { useNotificationStore } from '@/stores/notification.store';

@Options({
  props: {
    patientId: {
      type: String,
      required: true
    },
    encounterId: {
      type: String,
      default: null
    },
    patient: {
      type: Object,
      default: null
    },
    readonly: {
      type: Boolean,
      default: false
    },
    refreshScans: {
      type: Boolean,
      default: false
    }
  },
  components: { PageLoading, StudyGallery, Viewer }
})
export default class ScansPage extends Vue {
  studies: Array<Study> = [];
  signsList: Array<Indicator> = [];
  signs: Array<FullIndicator> = [];
  anatomies: Array<Anatomy> = [];
  inadequateOptions: Array<InadequateReason> = [];
  showViewer = false;
  seriesId = '';
  studyId = '';
  patient!: Patient | null;
  patientId!: string;
  encounterId!: string;
  perPage = 15;
  search = '';
  readonly!: boolean;
  refreshScans!: boolean;

  studiesLoading = true;
  test = process.env.NODE_ENV === 'development'; // always set test to true in development environment
  processedImages: { [seriesId: string]: string[] } = {};

  indicatorService = new IndicatorService();
  reviewIndicatorService = new PatientReviewIndicatorService(this.patientId, this.encounterId);

  anatomyService = new AnatomyService();
  inadequateReasonService = new InadequateReasonService();
  zephyrService = new ZephyrService(this.patientId);
  patientSeriesService = new PatientSeriesService(this.patientId);
  annotationsService = new ZephyrAnnotationService(); // TODO: test service
  zephyrImageService = new ZephyrImageService();
  request: CancelTokenSource | null = null;

  notificationStore = useNotificationStore();

  get updateSearchDebounced() {
    return debounce((search: string) => this.updateSearch(search), 500);
  }

  get tools(): Tool[] {
    return [
      {
        name: this.$t('custom.uhb.scans.freehand') as string,
        action: 'Freehand',
        cornerstoneName: 'FreehandRoi',
        config: {},
        icon: 'pen'
      },
      {
        name: this.$t('custom.uhb.scans.arrow') as string,
        action: 'Arrow',
        cornerstoneName: 'ArrowAnnotate',
        config: {},
        icon: 'arrow-top-left'
      },
      // { name: 'Rectangle', cornerstoneName: 'RectangleRoi', config: {}, icon: 'area' },
      {
        name: this.$t('custom.uhb.scans.length') as string,
        action: 'Distance',
        cornerstoneName: 'Length',
        config: {},
        icon: 'group'
      },
      {
        name: this.$t('custom.uhb.scans.angle') as string,
        action: 'Angle',
        cornerstoneName: 'Angle',
        config: {},
        icon: 'angle'
      },
      {
        name: this.$t('custom.uhb.scans.eraser') as string,
        cornerstoneName: 'Eraser',
        config: {},
        icon: 'delete'
      }
    ];
  }

  async updateSearch(search: string) {
    this.search = search;
    this.perPage = 15;
    await this.fetchSignsList();
  }

  get loadingSeries() {
    return this.sortedStudies[0]
      ? this.sortedStudies[0].series
        .filter(
          (series) =>
            !this.processedImages[series.id] || series.images.length > this.processedImages[series.id].length
        )
        .map((series) => series.id)
      : [];
  }

  get sortedStudies() {
    return this.studies
      .filter((study) => !!study.series.find((series) => series.images.length))
      .sort((a, b) => (dayjs(b.studyTime).isBefore(dayjs(a.studyTime)) ? -1 : 1));
  }

  get imagesRoute() {
    return this.zephyrImageService.getEndpointUrl();
  }

  get signsSuggestions(): Array<ISuggestion> {
    return this.signsList.map((sign) => ({
      id: sign.id,
      label: sign.name
    }));
  }

  get series(): Array<ImageSeries> {
    const study = this.studies.find((s) => s.id === this.studyId);
    return study ? study.series : [];
  }

  async created() {
    cornerstoneWebImageLoader.external.cornerstone = cornerstone;
    cornerstoneTools.external.cornerstoneMath = cornerstoneMath;
    cornerstoneTools.external.cornerstone = cornerstone;
    cornerstoneTools.external.Hammer = Hammer;

    try {
      this.studies = await this.getStudies();
      this.studiesLoading = false;
      this.inadequateOptions = await this.fetchInadequateOptions();
      if (!this.readonly) {
        this.preloadLastStudyImages();
        await this.fetchSignsList();
        this.anatomies = await this.fetchAnatomyList();
        this.signs = await this.fetchSigns();
      }

      this.$watch('refreshScans', async () => {
        if (this.refreshScans) {
          this.studiesLoading = true;
          this.studies = await this.getStudies();
          this.studiesLoading = false;
        }
      });
    } catch (e) {
      if (!axios.isCancel(e)) {
        this.notificationStore.addErrorNotification({
          title: this.$t('platform.error.load-data')
        });
      }
      this.studiesLoading = false;
    }
  }

  beforeUnmount() {
    imageLoaderService.clear();
    this.cancelRequest();
  }

  cancelRequest() {
    if (this.request) {
      this.request.cancel();
    }
  }

  async loadNextSigns() {
    this.perPage += 10;
    await this.fetchSignsList();
  }

  async fetchInadequateOptions() {
    return (await this.inadequateReasonService.index()).data;
  }

  async getStudies() {
    this.request = axios.CancelToken.source();
    const params: AxiosRequestConfig = {
      params: {
        thumbnails: 1,
        thumbnailWidth: 200
      },
      cancelToken: this.request.token
    };
    const response = this.test
      ? await new ZephyrService('666d40b3-d1b4-4700-b457-c822fe180304').getStudies(params)
      : await this.zephyrService.getStudies(params);
    this.$emit('loaded');
    return response;
  }

  async fetchAnatomyList() {
    return (await this.anatomyService.index()).data;
  }

  async fetchSigns() {
    return (
      await this.reviewIndicatorService.index({
        params: {
          include: 'indicator,indicator.type,anatomy',
          'filter[indicator.type.name]': 'sign',
          sort: 'indicators.name'
        }
      })
    ).data;
  }

  async fetchSignsList() {
    const response = (await this.indicatorService.index({
      params: {
        'filter[type]': 'sign',
        'filter[search]': this.search,
        perPage: this.perPage
      }
    })) as PaginatedResponse<Indicator[]>;
    this.signsList = response.data;
  }

  async addSign(sign: IndicatorRequest) {
    try {
      const indicator = (await this.reviewIndicatorService.post(sign)).data;
      this.signs = [...this.signs, indicator];
    } catch (e) {
      console.error(e);
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.scans.sign.create-error')
      });
    }
  }

  async removeSign(id: string) {
    try {
      await this.reviewIndicatorService.delete(id);
      this.signs = [...this.signs].filter((sign) => sign.id !== id);
    } catch (e) {
      console.error(e);
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.scans.sign.remove-error')
      });
    }
  }

  async createAnnotation(annotation: Partial<Annotation>) {
    try {
      const response = (await this.annotationsService.create({
        ...annotation
      })) as unknown as Annotation;
      const index = this.studies.findIndex((study) => study.id === annotation.studyId);
      const study = this.studies[index];
      const newStudy = {
        ...study,
        annotations: [
          ...study.annotations,
          {
            ...response,
            details: JSON.stringify(response.details)
          }
        ]
      };
      this.studies.splice(index, 1, newStudy);
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.scans.annotation.create-error')
      });
    }
  }

  async updateAnnotation(annotation: Annotation) {
    try {
      if (annotation.id && annotation.studyId) {
        await this.annotationsService.update(annotation.id, {
          ...annotation
        });
        const index = this.studies.findIndex((study) => study.id === annotation.studyId);
        const study = this.studies[index];
        const annotationIndex = study.annotations.findIndex((a) => a.id === annotation.id);
        const annotations = [...study.annotations];
        annotations.splice(annotationIndex, 1, {
          ...annotation,
          details: JSON.stringify(annotation.details)
        });
        const newStudy = {
          ...study,
          annotations
        };
        this.studies.splice(index, 1, newStudy);
      }
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.scans.annotation.update-error')
      });
    }
  }

  async removeAnnotation(annotation: { id: string; studyId: string }) {
    try {
      await this.annotationsService.delete(annotation.id);
      const index = this.studies.findIndex((study) => study.id === annotation.studyId);
      const study = this.studies[index];
      const newStudy = {
        ...study,
        annotations: study.annotations.filter((a) => a.id !== annotation.id)
      };
      this.studies.splice(index, 1, newStudy);
    } catch (e) {
      this.notificationStore.addErrorNotification({
        title: this.$t('custom.uhb.scans.annotation.delete-error')
      });
    }
  }

  preloadLastStudyImages() {
    if (this.sortedStudies.length) {
      imageLoaderService.pushStudy(this.imagesRoute, this.sortedStudies[0], 1, (task?: LoaderTask) => {
        if (task) {
          this.studies = this.removeImage(task.studyId, task.seriesId, task.id);
        }
        this.updateProcessedImages();
      });
    }
  }

  removeImage(studyId: string, seriesId: string, imageId: string): Array<Study> {
    const studyIndex = this.studies.findIndex((study) => study.id === studyId);
    const seriesIndex = this.studies[studyIndex].series.findIndex((series) => series.id === seriesId);
    const serie = {
      ...this.studies[studyIndex].series[seriesIndex],
      images: this.studies[studyIndex].series[seriesIndex].images.filter((i) => i.id !== imageId)
    };
    const series = [...this.studies[studyIndex].series];
    series.splice(seriesIndex, 1, serie);
    const study: Study = {
      ...this.studies[studyIndex],
      series
    };
    const studies = [...this.studies];
    studies.splice(studyIndex, 1, study);
    return studies;
  }

  addInadequacies(seriesId: string, inadequacies: Array<Inadequacy>): Array<Study> {
    const studyIndex = this.studies.findIndex((study) => study.series.find((s) => s.id === seriesId));
    const seriesIndex = this.studies[studyIndex].series.findIndex((series) => series.id === seriesId);
    const serie = {
      ...this.studies[studyIndex].series[seriesIndex],
      inadequacies
    };
    const series = [...this.studies[studyIndex].series];
    series.splice(seriesIndex, 1, serie);
    const study: Study = {
      ...this.studies[studyIndex],
      series
    };
    const studies = [...this.studies];
    studies.splice(studyIndex, 1, study);
    return studies;
  }

  openViewer(data: { seriesId: string; studyId: string }) {
    this.seriesId = data.seriesId;
    this.studyId = data.studyId;
    const series = this.series.find((s) => s.id === data.seriesId);
    if (series) {
      imageLoaderService.unshiftSeries(data.studyId, this.imagesRoute, series, this.updateProcessedImages);
    }
    this.showViewer = true;
  }

  updateProcessedImages() {
    this.processedImages = imageLoaderService.processedImages;
  }

  async setInadequate(data: { seriesId: string; reasons: Array<string> }) {
    const service = this.test
      ? new PatientSeriesService('666d40b3-d1b4-4700-b457-c822fe180304')
      : this.patientSeriesService;
    const reasons = data.reasons.map((id) => ({
      id,
      comments: null
    }));
    const response = await service.markAsInadequate(data.seriesId, reasons);
    this.studies = this.addInadequacies(data.seriesId, response.data).slice();
  }

  closeViewer() {
    this.seriesId = '';
    this.studyId = '';
    this.showViewer = false;
  }
}
